@import "../../scss/base.scss";

.modalOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: $modalOverlayBg;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  @include mobile {
    // top: 65px;
    background-color: unset;
    // min-height: calc(100vh - 65px);
    -webkit-overflow-scrolling: touch;
  }
}

.modal {
  position: relative;
  max-width: 65%;
  box-shadow: 0 3px 26px 0 rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 110px);
  overflow: auto;

  @include mobile {
    height: 100vh;
    width: 100vw;
    // top: 32px;
    box-shadow: unset;
    max-height: unset;
    max-width: unset;
    border-radius: unset;
    overflow: auto;
    // min-height: calc(100vh - 65px);
    min-height: 100vh;
  }

  .play-button-overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .2);
    top: 0;
    z-index: 3;

    @include mobile {
      border-radius: 0px;
    }
  }

  .content {
    background-color: #393939;
    box-shadow: 4px 4px 10px rgba(57, 57, 57, 0.1);
    @include mobile {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }


    .player-wrapper {
      padding: 0 86px;
      @include mobile {
        padding: 74px 0;
      }
    }

    .horizontalPlayerWrapper {
      height: 100vh;
      width: 100vw;
      max-width: unset;
      top: 0;
      padding: 0;
      z-index: 2;
    }

    .player-controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      bottom: 10px;
      padding: 0 13%;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      z-index: 4;

      progress {
        flex: 1;
        height: 6px;
        padding: 0 10px;
      }
      progress[value] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: none;
      }

      progress[value]::-webkit-progress-bar {
        background-color: #fffefc;
        border-radius: 5px;
      }

      progress[value]::-webkit-progress-value {
        background-color: #00AD66;
        border-radius: 5px;
      }

      @include mobile {
        bottom: 40px;
      }
    }

    .pause-button-mobile {
      position: absolute;
      border-radius: 10px;
      top: 10px;
      left: 10px;
      z-index: 3;
    }

    .duration {
      font: 12px Circular Std Blk, Helvetica, Arial, sans-serif;
      color: white;
    }

    .durationWrapper {
      position: absolute;
      bottom: 20px;
      left: 10px;
    }
  }
}

.horizontalModal {
  top: 0;
  overflow: unset;
  max-height: unset;
  max-width: unset;
}

.modal:focus-visible {
  outline: none;
}

.closeButton {
  position: absolute;
  right: 25px;
  top: 25px;
  z-index: 10;
  cursor: pointer;
  img {
    width: 30px;
    height: 30px;
  }
  @include mobile {
    top: 50px;
    right: 10px;
  }
}

.content {
  width: auto;
  background-color: white;

  .footer {
    font: 16px/20px circular Std Book, Helvetica, Arial, sans-serif;
    display: flex;
    width: 100%;
    color: #393939;
    justify-content: center;
    margin: 2rem 0;
    @include mobile {
      font-size: 14px;
      line-height: 18px;
    }
    .link {
      color: #00ad66;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
      margin-left: 0.5rem;
    }
  }
}
