$dashboard-width: 1545px;
// color
$baseGreenLight: #a6e8cd;
$secondaryLightGreen: #c6f6e3;
$baseGreen: #00ad66;
$baseRedColor: #ff1e57;
$baseDarkRed: #e51b4e;
$baseBlack: #393939;
$baseGreyColor: #8f8e8e;
$baseInputEdit: #fffaf3;
$modalOverlayBg: #0000009a;
$baseLightGrey: #ececec;

// font
$mawoo-font-black:
  Circular Std Blk,
  Helvetica,
  Arial,
  sans-serif;
$mawoo-font-bold:
  Circular Std bld,
  Helvetica,
  Arial,
  sans-serif;
$mawoo-font-medium:
  Circular Std Medium,
  Helvetica,
  Arial,
  sans-serif;
$mawoo-font-book:
  Circular Std Book,
  Helvetica,
  Arial,
  sans-serif;

@mixin laptop {
  @media (max-width: 1700px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 1270px) {
    @content;
  }
}

@mixin small-tablet {
  @media (max-width: 860px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin no-mobile {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin content-width {
  max-width: $dashboard-width;

  @include laptop {
    max-width: 1160px;
  }

  @include tablet {
    max-width: 720px;
  }

  @include mobile {
    max-width: 100vw;
  }
}

@mixin min-content-width {
  min-width: 1200px;

  @include laptop {
    min-width: 800px;
  }

  @include tablet {
    min-width: 600px;
  }

  @include mobile {
    min-width: 80vw;
  }
}

@mixin two-column-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.squareImage {
  width: 100%;
  padding-top: 100%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  .image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: cover;
    background-position: center;
  }
}

@mixin baseRedColor {
  color: #ff1e57;
}

@mixin baseBlack {
  color: $baseBlack;
}

@mixin baseGreenColor {
  color: #00ad66;
  & a {
    color: #00ad66;
    text-decoration: none;
  }
}

.cancel {
  cursor: pointer;
  font-weight: bold;

  @include baseRedColor;
}

.add {
  cursor: pointer;
  font-weight: bold;

  @include baseGreenColor;
}

@mixin title {
  font-family:
    Circular Std Bld,
    Helvetica,
    Arial,
    sans-serif;
  font-size: 25px;
  line-height: 32px;
  @include baseBlack;
  @include mobile {
    font-size: 20px;
    line-height: 23px;
    margin-top: 20px;
  }
}

@mixin text {
  font-family:
    Circular Std Book,
    Helvetica,
    Arial,
    sans-serif;
  font-size: 18px;
  line-height: 23px;
  & a {
    color: $baseGreen;
  }
  @include baseBlack;
  @include mobile {
    font-size: 14px;
    line-height: 18px;
  }
}
